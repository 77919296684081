export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : { r: 0, g: 0, b: 0 }
}

export const calculateBrightness = (rgbColor: {
  r: number
  b: number
  g: number
}) => {
  return Math.round(
    (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000
  )
}

export const isHexString = (hex: string) => /^#([0-9a-f]{3}){1,2}$/i.test(hex)

export type ColorValueHex = `#${string}`

export default hexToRgb
