import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Translate } from 'next-translate'

dayjs.extend(duration)

interface FormatDurationTextProps {
  durationInMins: number
  continuous?: boolean
  variant?: string
  t: Translate
}

/**
 * Activities can be up to 12 hours in duration OR multi day
 * Any activity with a duration greater than 12 hours will be at least 48 hours
 * It is not possible to have a duration between 12 and 48 hours.
 */
const formatDurationText = ({
  durationInMins,
  continuous = false,
  variant = 'long',
  t
}: FormatDurationTextProps) => {
  const dayjsDuration = dayjs.duration(durationInMins, 'minutes')

  /**
   * The display is: hours > 12 = 1 day, hours > 24 = 2 days, etc
   */
  if (dayjsDuration.days() > 0) {
    const durationStr = continuous ? 'common:nights' : 'common:days'
    const durationCount = continuous
      ? dayjsDuration.days() - 1
      : dayjsDuration.days()
    const daysTranslation = t(durationStr, { count: durationCount })

    return `${durationCount} ${daysTranslation}`
  }

  let textStr = ''

  const shortForm = variant === 'short' ? 'short' : 'shortened'

  if (dayjsDuration.hours() > 0) {
    textStr +=
      dayjsDuration.hours() +
      (variant === 'short' ? '' : ' ') +
      t(`common:${shortForm}-hours`, {
        count: dayjsDuration.hours()
      }) +
      ' '
  }

  if (dayjsDuration.minutes() > 0) {
    textStr +=
      dayjsDuration.minutes() +
      (variant === 'short' ? '' : ' ') +
      t(`common:${shortForm}-minutes`, {
        count: dayjsDuration.minutes()
      }) +
      ' '
  }

  return textStr.trim()
}

export default formatDurationText
