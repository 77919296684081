export enum ChargeableType {
  Booking = 'Booking',
  PurchasedStoreItem = 'PurchasedStoreItem',
  CreditVoucher = 'CreditVoucher',
  PurchasedClassPass = 'PurchasedClassPass',
  ArbitraryTransaction = 'ArbitraryTransaction'
}

export const VouchersTypeEnum = {
  ActivityVoucher: 'ActivityVoucher',
  CreditVoucher: 'CreditVoucher',
  ClassPasses: 'ClassPasses',
  PurchasedClassPass: 'PurchasedClassPass'
}
